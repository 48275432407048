import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDefaultHeaders } from 'store/utils/getDefaultHeaders';
import {
  CreateStorageDirectoryPayload,
  StorageDirectory,
  StorageFile,
  StorageRelationType,
} from 'store/storage/interfaces';
import { ApiFilters, ApiResponse } from 'helpers/interfaces';
import buildQueryString from 'store/utils/buildQueryString';

export const uploadFile = createAsyncThunk(
  'storage/uploadFile',
  async (payload: FormData, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/storage/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: getDefaultHeaders()['Authorization'],
          Accept: getDefaultHeaders()['Accept'],
        },
        body: payload,
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as StorageFile);
  },
);

export const fetchStorageDirectories = createAsyncThunk(
  'storage/fetchStorageDirectories',
  async (filters: ApiFilters<StorageDirectory>, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/storage-directories${buildQueryString(filters)}`,
      {
        headers: getDefaultHeaders(),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as ApiResponse<StorageDirectory>);
  },
);

export const createStorageDirectory = createAsyncThunk(
  'storage/createStorageDirectory',
  async (payload: CreateStorageDirectoryPayload, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/storage-directories`,
      {
        method: 'POST',
        headers: getDefaultHeaders(),
        body: JSON.stringify(payload),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as StorageDirectory);
  },
);

export const fetchStorageFiles = createAsyncThunk(
  'storage/fetchStorageFiles',
  async (
    filters: ApiFilters<
      StorageDirectory & {
        relationId: string;
        relationName: StorageRelationType;
      }
    >,
    thunkAPI,
  ) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/storage${buildQueryString(filters)}`,
      {
        headers: getDefaultHeaders(),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as ApiResponse<StorageFile>);
  },
);

export const fetchImagePreview = createAsyncThunk(
  'storage/fetchImagePreview',
  async (payload: { fileId: string }, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/storage/${payload.fileId}/preview`,
      {
        headers: getDefaultHeaders(),
      },
    );

    if (!response.ok) {
      return thunkAPI.rejectWithValue(await response.json());
    }

    const blob = await response.blob();
    return thunkAPI.fulfillWithValue(URL.createObjectURL(blob));
  },
);

export const deleteStorgeFile = createAsyncThunk(
  'storage/deleteStorgeFile',
  async (payload: { fileId: string }, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/storage/${payload.fileId}`,
      {
        method: 'DELETE',
        headers: getDefaultHeaders(),
      },
    );

    if (!response.ok) {
      return thunkAPI.rejectWithValue(response.status);
    }

    return thunkAPI.fulfillWithValue({ fileId: payload.fileId });
  },
);
