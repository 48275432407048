import React from 'react';
import { RouteObject } from 'react-router';
import OnlyUnauthorizedRoute from 'components/OnlyUnauthorizedRoute/OnlyUnauthorizedRoute';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import PasswordReset from 'pages/PasswordReset/PasswordReset';
import SignIn from 'pages/SignIn/SignIn';
import SignUp from 'pages/SignUp/SignUp';
import NotFound from 'pages/NotFound/NotFound';
import AccountActivation from 'pages/AccountActivation/AccountActivation';
import PasswordSetup from 'pages/PasswordSetup/PasswordSetup';
import Articles from 'pages/Articles/Articles';
import Settings from 'pages/Settings/Settings';
import Branches from 'pages/Branches/Branches';
import Files from 'pages/Files/Files';

export const routes = [
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Branches />
      </ProtectedRoute>
    ),
  },
  {
    path: '/articles',
    element: (
      <ProtectedRoute>
        <Articles />
      </ProtectedRoute>
    ),
  },
  {
    path: '/files',
    element: (
      <ProtectedRoute>
        <Files />
      </ProtectedRoute>
    ),
  },
  {
    path: '/settings',
    element: (
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: '/login',
    element: (
      <OnlyUnauthorizedRoute>
        <SignIn />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    path: '/register',
    element: (
      <OnlyUnauthorizedRoute>
        <SignUp />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    path: '/password-reset',
    element: (
      <OnlyUnauthorizedRoute>
        <PasswordReset />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    path: '/activation/:token',
    element: (
      <OnlyUnauthorizedRoute>
        <AccountActivation />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    path: '/password-setup/:token',
    element: (
      <OnlyUnauthorizedRoute>
        <PasswordSetup />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    path: '*',
    element: <NotFound />,
  },
] as RouteObject[];

export default routes;
