export enum StorageRelationType {
  userAvatar = 'user-avatar',
  storageDirectory = 'storage-directory',
}

export interface StorageFile {
  '@id': string;
  '@type': string;
  id: string;
  size: number;
  mimeType: string;
  relationName: string;
  filename: string;
  url: string;
}

export interface StorageState {
  isRequestPending: boolean;
  storageDirectories: StorageDirectory[] | null;
  currentStorageDirectory: StorageDirectory | null;
  currentStorageDirectoryFiles: StorageFile[] | null;
}

export interface StorageDirectory {
  '@id': string;
  '@type': string;
  id: string;
  project: string;
  parent?: StorageDirectory;
  name: string;
  children: StorageDirectory[];
}

export type CreateStorageDirectoryPayload = Pick<
  StorageDirectory,
  'project' | 'name'
> & { parent: string };
