import React, { MouseEvent, useState } from 'react';
import { Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import { Article } from 'store/articles/interfaces';
import ActionConfirmationDialog from 'components/Dialogs/ActionConfirmationDialog/ActionConfirmationDialog';

interface ContextMenuProps {
  anchorEl: HTMLElement | null;
  article: Article;
  isMaster?: boolean;
  handleMenuClose: (event: MouseEvent<HTMLElement>) => void;
}

function ContextMenu({
  anchorEl,
  article,
  isMaster,
  handleMenuClose,
}: ContextMenuProps) {
  const theme = useTheme();
  const [confirmationDialogState, setConfirmationDialogState] = useState<{
    isOpen: boolean;
    title: string;
    content: string;
    onConfirm: () => void;
  }>({
    isOpen: false,
    title: '',
    content: '',
    onConfirm: () => {},
  });

  const handleDeleteArticle = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleMenuClose(event);
    setConfirmationDialogState({
      isOpen: true,
      title: t('delete_article'),
      content: t('delete_article_confirmation'),
      onConfirm: () => {
        console.log('Delete article:', article);
      },
    });
  };

  const handleRevertChanges = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleMenuClose(event);
    setConfirmationDialogState({
      isOpen: true,
      title: t('revert_changes'),
      content: t('revert_changes_confirmation'),
      onConfirm: () => {
        console.log('Revert to master:', article);
      },
    });
  };

  const handleRevertDeletion = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleMenuClose(event);
    setConfirmationDialogState({
      isOpen: true,
      title: t('revert_deletion'),
      content: t('revert_deletion_confirmation'),
      onConfirm: () => {
        console.log('Revert deletion:', article);
      },
    });
  };

  const handleCloseConfirmation = () => {
    setConfirmationDialogState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        onClick={(event) => event.stopPropagation()}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        elevation={0}
        sx={{
          '& .MuiPaper-root': {
            border: `1px solid ${theme.vars.palette.divider}`,
          },
          '& .MuiMenuItem-root': {
            minHeight: 32,
          },
        }}
      >
        {isMaster ? (
          <MenuItem component="div" onClick={handleDeleteArticle}>
            <Typography variant="caption">{t('delete_article')}</Typography>
          </MenuItem>
        ) : (
          <>
            <MenuItem component="div" onClick={handleRevertChanges}>
              <Typography variant="caption">{t('revert_changes')}</Typography>
            </MenuItem>
            <MenuItem component="div" onClick={handleRevertDeletion}>
              <Typography variant="caption">{t('revert_deletion')}</Typography>
            </MenuItem>
          </>
        )}
      </Menu>
      <ActionConfirmationDialog
        isDialogOpen={confirmationDialogState.isOpen}
        title={confirmationDialogState.title}
        content={confirmationDialogState.content}
        handleConfirmAction={confirmationDialogState.onConfirm}
        handleDialogClose={handleCloseConfirmation}
      />
    </>
  );
}

export default ContextMenu;
