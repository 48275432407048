import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { t } from 'i18next';
import { User } from 'store/authorization/interfaces';
import { fetchUser } from 'store/authorization/thunks';
import { extractIdFromIri } from 'helpers/extractIdFromIri';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isRequestPending } from 'store/authorization/selectors';
import useDisplayNotification from 'hooks/useDisplayNotification';

interface AuthorNameProps {
  authorIri: string;
}

function AuthorName({ authorIri }: AuthorNameProps) {
  const [author, setAuthor] = useState<User | null>(null);

  const dispatch = useAppDispatch();
  const { displayErrorNotification } = useDisplayNotification();
  const requestPending = useAppSelector(isRequestPending);

  useEffect(() => {
    const fetchAuthor = async () => {
      try {
        const userData = await dispatch(
          fetchUser({ id: extractIdFromIri(authorIri) }),
        ).unwrap();
        setAuthor(userData);
      } catch (error) {
        displayErrorNotification(error);
      }
    };

    if (!authorIri) {
      return;
    }

    fetchAuthor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorIri, dispatch]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.8 }}>
      <Typography variant="body1">{t('author')}:</Typography>
      {requestPending ? (
        <Skeleton animation="wave" width={155} />
      ) : (
        <Typography variant="body1">
          {author?.firstname} {author?.lastname}
        </Typography>
      )}
    </Box>
  );
}

export default AuthorName;
