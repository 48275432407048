import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Fade,
} from '@mui/material';
import { t } from 'i18next';
import { StorageFile } from 'store/storage/interfaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteStorgeFile, fetchImagePreview } from 'store/storage/thunks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { isRequestPending } from 'store/storage/selectors';

interface ImagePreviewDialogProps {
  isDialogOpen: boolean;
  handleDialogClose: () => void;
  image: StorageFile | null;
}

function ImagePreviewDialog({
  isDialogOpen,
  handleDialogClose,
  image,
}: ImagePreviewDialogProps) {
  const dispatch = useAppDispatch();
  const { displayErrorNotification } = useDisplayNotification();
  const requestPending = useAppSelector(isRequestPending);

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadImage = async () => {
      if (!image || previewUrl) {
        return;
      }

      try {
        const url = await dispatch(
          fetchImagePreview({ fileId: image.id }),
        ).unwrap();

        setPreviewUrl(url);
      } catch (error) {
        displayErrorNotification(error);
      }
    };

    loadImage();

    return () => {
      if (!previewUrl) {
        return;
      }

      URL.revokeObjectURL(previewUrl);
    };
  }, [dispatch, displayErrorNotification, image, previewUrl]);

  const handlePreviewDialogClose = () => {
    handleDialogClose();
    setPreviewUrl(null);

    if (!previewUrl) {
      return;
    }

    URL.revokeObjectURL(previewUrl);
  };

  const handleDeleteFileClick = async () => {
    if (!image?.id) {
      return;
    }

    try {
      await dispatch(deleteStorgeFile({ fileId: image?.id })).unwrap();
      handlePreviewDialogClose();
    } catch (error) {
      displayErrorNotification(error);
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handlePreviewDialogClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={{ pb: 1 }} color="primary">
        {t('image_preview')}
      </DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        <Box
          sx={{
            pt: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            height: '50vh',
          }}
        >
          <Fade in={!previewUrl} timeout={200}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height="50vh"
              sx={{ display: !previewUrl ? 'block' : 'none' }}
            />
          </Fade>
          <Fade in={!!previewUrl} timeout={500}>
            <Box
              component="img"
              src={previewUrl ?? ''}
              alt={image?.id}
              loading="lazy"
              sx={{
                maxWidth: '100%',
                maxHeight: '50vh',
                objectFit: 'contain',
                display: previewUrl ? 'block' : 'none',
              }}
            />
          </Fade>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button
          color="error"
          onClick={handleDeleteFileClick}
          disabled={requestPending}
        >
          {t('delete')}
        </Button>
        <Button autoFocus onClick={handlePreviewDialogClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImagePreviewDialog;
