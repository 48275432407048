import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'store/storage/defaultState';
import {
  createStorageDirectory,
  deleteStorgeFile,
  fetchStorageDirectories,
  fetchStorageFiles,
  uploadFile,
} from 'store/storage/thunks';
import { StorageDirectory } from 'store/storage/interfaces';

export const storage = createSlice({
  name: 'storage',
  initialState: defaultState,
  reducers: {
    setCurrentStorageDirectoryById: (
      draft,
      action: PayloadAction<{ storageDirectoryId: string | null }>,
    ) => {
      const chosenStorageDirectory = draft.storageDirectories?.find(
        (directory) => directory.id === action.payload.storageDirectoryId,
      );

      draft.currentStorageDirectory = chosenStorageDirectory || null;
    },

    setStorageDirectories: (
      draft,
      action: PayloadAction<StorageDirectory[] | null>,
    ) => {
      draft.storageDirectories = action.payload;
    },

    resetStorageDirectoriesState: (draft) => {
      draft.storageDirectories = defaultState.storageDirectories;
      draft.currentStorageDirectory = defaultState.currentStorageDirectory;
      draft.currentStorageDirectoryFiles =
        defaultState.currentStorageDirectoryFiles;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFile.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(uploadFile.fulfilled, (draft, action) => {
      if (!draft.currentStorageDirectoryFiles) {
        draft.currentStorageDirectoryFiles = [action.payload];
        return;
      }

      draft.currentStorageDirectoryFiles?.push(action.payload);
      draft.isRequestPending = false;
    });
    builder.addCase(uploadFile.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(fetchStorageDirectories.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchStorageDirectories.fulfilled, (draft, action) => {
      const { 'hydra:member': storageDirectories } = action.payload;
      draft.storageDirectories = storageDirectories;

      const root = storageDirectories.find((directory) => !directory?.parent);

      if (root) {
        draft.currentStorageDirectory = root;
      }

      draft.isRequestPending = false;
    });
    builder.addCase(fetchStorageDirectories.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(createStorageDirectory.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(createStorageDirectory.fulfilled, (draft, action) => {
      if (!draft?.storageDirectories) {
        return;
      }
      draft.storageDirectories.push(action.payload);
      draft.isRequestPending = false;
    });
    builder.addCase(createStorageDirectory.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(fetchStorageFiles.pending, (draft) => {
      draft.isRequestPending = true;
      draft.currentStorageDirectoryFiles = null;
    });
    builder.addCase(fetchStorageFiles.fulfilled, (draft, action) => {
      const { 'hydra:member': storageItems } = action.payload;
      draft.currentStorageDirectoryFiles = storageItems;
      draft.isRequestPending = false;
    });
    builder.addCase(fetchStorageFiles.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(deleteStorgeFile.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(deleteStorgeFile.fulfilled, (draft, action) => {
      if (!draft.currentStorageDirectoryFiles) {
        return;
      }

      draft.currentStorageDirectoryFiles =
        draft.currentStorageDirectoryFiles.filter(
          (file) => file.id !== action.payload.fileId,
        );

      draft.isRequestPending = false;
    });
    builder.addCase(deleteStorgeFile.rejected, (draft) => {
      draft.isRequestPending = false;
    });
  },
});

export const {
  setCurrentStorageDirectoryById,
  setStorageDirectories,
  resetStorageDirectoriesState,
} = storage.actions;

export default storage.reducer;
