import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { t } from 'i18next';
import SideSection from 'components/Layout/AppDrawer/SideSection/SideSection';
import Label from 'components/Layout/AppDrawer/SideSection/Label/Label';
import { useAppSelector } from 'store/hooks';
import { articles, currentArticle } from 'store/articles/selectors';
import ArticleItem from 'pages/Articles/SideSectionContent/ArticleItem/ArticleItem';
import { currentBranch } from 'store/branches/selectors';

interface SideSectionContentProps {
  setIsArticleDialogOpen: Dispatch<SetStateAction<boolean>>;
}

function SideSectionContent({
  setIsArticleDialogOpen,
}: SideSectionContentProps) {
  const { project: projectArticles, branch: branchArticles } =
    useAppSelector(articles);
  const activeArticle = useAppSelector(currentArticle);
  const branch = useAppSelector(currentBranch);

  const allArticles = [...(projectArticles || []), ...(branchArticles || [])];

  return (
    <SideSection
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Box>
        {allArticles.length ? (
          <>
            {projectArticles?.map((article) => (
              <ArticleItem
                key={article.id}
                article={article}
                isActive={activeArticle?.id === article.id}
                isMaster
              />
            ))}
            {branch && (
              <>
                {branchArticles?.map((article) => (
                  <ArticleItem
                    key={article.id}
                    article={article}
                    isActive={activeArticle?.id === article.id}
                  />
                ))}
              </>
            )}
          </>
        ) : (
          <Label>{t('no_articles')}</Label>
        )}
      </Box>
      <Button
        variant="contained"
        size="large"
        color="inherit"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setIsArticleDialogOpen(true)}
        sx={{
          m: 1,
        }}
      >
        {t('new_article')}
      </Button>
    </SideSection>
  );
}

export default SideSectionContent;
