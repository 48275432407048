import React from 'react';
import { Box, Typography } from '@mui/material';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import PendingTwoToneIcon from '@mui/icons-material/PendingTwoTone';
import { t } from 'i18next';

interface ArticleStatusIndicatorProps {
  isArticleChanged: boolean;
  requestPending: boolean;
}

function ArticleStatusIndicator({
  isArticleChanged,
  requestPending,
}: ArticleStatusIndicatorProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 65,
      }}
    >
      {!isArticleChanged && !requestPending && (
        <>
          <CircleTwoToneIcon color="success" />
          <Typography variant="caption" color="success">
            {t('saved')}
          </Typography>
        </>
      )}

      {isArticleChanged && !requestPending && (
        <>
          <CircleTwoToneIcon color="error" />
          <Typography variant="caption" color="error">
            {t('unsaved')}
          </Typography>
        </>
      )}

      {requestPending && (
        <>
          <PendingTwoToneIcon color="warning" />
          <Typography variant="caption" color="warning">
            {t('saving')}
          </Typography>
        </>
      )}
    </Box>
  );
}

export default ArticleStatusIndicator;
