import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AccountActivationPayload,
  FetchUserPayload,
  PasswordResetPayload,
  PasswordSetupPayload,
  SignInPayload,
  SignInResponse,
  SignUpPayload,
  User,
} from 'store/authorization/interfaces';
import {
  getDefaultHeaders,
  getDefaultUnauthorizedHeaders,
} from 'store/utils/getDefaultHeaders';

export const signUp = createAsyncThunk(
  'authorization/singUp',
  async (payload: SignUpPayload, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/users/register`,
      {
        headers: getDefaultUnauthorizedHeaders(),
        method: 'POST',
        body: JSON.stringify(payload),
      },
    );

    if (!response.ok) {
      return thunkAPI.rejectWithValue(await response.json());
    }

    return thunkAPI.fulfillWithValue(null);
  },
);

export const signIn = createAsyncThunk(
  'authorization/singIn',
  async (payload: SignInPayload, thunkAPI) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/login`, {
      headers: getDefaultUnauthorizedHeaders(),
      method: 'POST',
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as SignInResponse);
  },
);

export const passwordReset = createAsyncThunk(
  'authorization/passwordReset',
  async (payload: PasswordResetPayload, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/users/password-reset`,
      {
        headers: getDefaultUnauthorizedHeaders(),
        method: 'POST',
        body: JSON.stringify(payload),
      },
    );

    if (!response.ok) {
      return thunkAPI.rejectWithValue(await response.json());
    }

    return thunkAPI.fulfillWithValue(null);
  },
);

export const passwordSetup = createAsyncThunk(
  'authorization/passwordSetup',
  async (payload: PasswordSetupPayload, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/users/password-setup/${payload.token}`,
      {
        headers: getDefaultUnauthorizedHeaders(),
        method: 'POST',
        body: JSON.stringify({
          password: payload.password,
          confirm: payload.confirm,
        }),
      },
    );

    if (!response.ok) {
      return thunkAPI.rejectWithValue(await response.json());
    }

    return thunkAPI.fulfillWithValue(null);
  },
);

export const accountActivation = createAsyncThunk(
  'authorization/accountActivation',
  async (payload: AccountActivationPayload, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/users/activation/${payload.token}`,
      {
        headers: getDefaultUnauthorizedHeaders(),
        method: 'POST',
        body: payload.token,
      },
    );

    if (!response.ok) {
      return thunkAPI.rejectWithValue(await response.json());
    }

    return thunkAPI.fulfillWithValue(null);
  },
);

export const fetchCurrentUser = createAsyncThunk(
  'authorization/fetchCurrentUser',
  async (_, thunkAPI) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/users/me`, {
      headers: getDefaultHeaders(),
    });

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as User);
  },
);

export const fetchUser = createAsyncThunk(
  'authorization/fetchUser',
  async (payload: FetchUserPayload, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/users/${payload.id}`,
      {
        headers: getDefaultHeaders(),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as User);
  },
);
