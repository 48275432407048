import React, { MouseEvent, useState } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import { Article } from 'store/articles/interfaces';
import ActionButton from 'common/ActionButton/ActionButton';
import ContextMenu from '../ContextMenu/ContextMenu';
import { useAppDispatch } from 'store/hooks';
import { setCurrentArticleById } from 'store/articles/articles';

interface ArticleItemProps {
  article: Article;
  isActive: boolean;
  isMaster?: boolean;
}

function ArticleItem({ article, isActive, isMaster }: ArticleItemProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();

  const setCurrentArticle = (article: Article) => {
    dispatch(setCurrentArticleById({ articleId: article.id }));
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const getArticleIconColor = () => {
    if (article?.origin) {
      return theme.palette.primary.light;
    }

    if (isMaster) {
      return theme.palette.background.bombay;
    }

    return theme.palette.success.lighter;
  };

  return (
    <>
      <ActionButton
        onClick={() => setCurrentArticle(article)}
        sx={{
          bgcolor: isActive ? theme.palette.action.selected : 'transparent',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CircleTwoToneIcon
            fontSize="small"
            sx={{
              color: getArticleIconColor(),
            }}
          />
          <Typography
            variant="body2"
            color="text.primary"
            textAlign="left"
            sx={{
              mt: 0.25,
            }}
          >
            {article.title}
          </Typography>
        </Box>
        {isActive && (
          <IconButton
            sx={{ height: 24, width: 24, borderRadius: 1 }}
            onClick={handleMenuOpen}
            component="div"
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        )}
      </ActionButton>
      <ContextMenu
        anchorEl={anchorEl}
        isMaster={isMaster}
        article={article}
        handleMenuClose={handleMenuClose}
      />
    </>
  );
}

export default ArticleItem;
