import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/Layout';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import { Button, Divider, List, ListItem, Typography } from '@mui/material';
import BranchDialog from 'components/Dialogs/BranchDialog/BranchDialog';
import SideSectionContent from 'pages/Branches/SideSectionContent/SideSectionContent';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Grid from '@mui/material/Grid2';
import { t } from 'i18next';
import { Branch } from 'store/branches/interfaces';
import { setCurrentBranchById } from 'store/branches/branches';
import {
  resetBranchArticles,
  setCurrentArticleById,
} from 'store/articles/articles';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import getBranchStatusColor from './utils/getBranchStatusColor';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import AuthorName from 'pages/Branches/AuthorName/AuthorName';
import { currentBranch } from 'store/branches/selectors';

function Branches() {
  const [isBranchDialogOpen, setIsBranchDialogOpen] = useState(false);
  const activeBranch = useAppSelector(currentBranch);

  const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null);

  useEffect(() => {
    setSelectedBranch(activeBranch);
  }, [activeBranch]);

  const dispatch = useAppDispatch();

  const setCurrentBranch = (branch: Branch | null) => {
    dispatch(setCurrentBranchById({ branchId: branch?.id || null }));
    dispatch(setCurrentArticleById({ articleId: null }));
    dispatch(resetBranchArticles());
  };

  return (
    <>
      <Layout
        sideSection={
          <SideSectionContent
            selectedBranch={selectedBranch}
            setSelectedBranch={setSelectedBranch}
            setIsBranchDialogOpen={setIsBranchDialogOpen}
          />
        }
      >
        <RoundedBox sx={{ p: 3, height: '100%' }}>
          <Grid container spacing={2}>
            <Grid
              size={12}
              sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}
            >
              <List
                sx={{
                  width: { xs: '100%', sm: 'fit-content' },
                  minWidth: 255,
                  py: 0,
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                }}
              >
                {selectedBranch ? (
                  <>
                    <ListItem>
                      <Typography variant="h5">{`${t('branch_name')}: ${selectedBranch.name}`}</Typography>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                      <Typography variant="body1">{`${t('status')}: ${t(selectedBranch.status)}`}</Typography>
                      <CircleTwoToneIcon
                        fontSize="small"
                        sx={{
                          color: getBranchStatusColor(selectedBranch),
                          ml: 1,
                        }}
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                      <AuthorName authorIri={selectedBranch.author} />
                    </ListItem>
                  </>
                ) : (
                  <ListItem>
                    <Typography variant="h5">{`${t('branch_name')}: ${t('master')}`}</Typography>
                  </ListItem>
                )}
              </List>

              <Button
                variant="outlined"
                size="large"
                startIcon={<ChangeCircleOutlinedIcon />}
                onClick={() => setCurrentBranch(selectedBranch)}
                sx={{ width: { xs: '100%', sm: 'fit-content' } }}
              >
                {t('switch_to_this_branch')}
              </Button>
            </Grid>
          </Grid>
        </RoundedBox>
      </Layout>
      <BranchDialog
        isDialogOpen={isBranchDialogOpen}
        handleDialogClose={() => setIsBranchDialogOpen(false)}
      />
    </>
  );
}

export default Branches;
