import React from 'react';
import { Typography, useTheme } from '@mui/material';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import ActionButton from 'common/ActionButton/ActionButton';
import { Branch, DefaultBranch } from 'store/branches/interfaces';
import getBranchStatusColor from 'pages/Branches/utils/getBranchStatusColor';
import { t } from 'i18next';

interface BranchItemProps {
  branch: Branch | null;
  isSelected: boolean;
  isActive: boolean;
  onSelect: (branch: Branch | null) => void;
}

function BranchItem({
  branch,
  isSelected,
  isActive,
  onSelect,
}: BranchItemProps) {
  const theme = useTheme();

  return (
    <ActionButton
      key={branch?.id || DefaultBranch.master}
      onClick={() => onSelect(branch)}
      sx={{
        bgcolor: isSelected ? theme.palette.action.selected : 'transparent',
      }}
    >
      <CircleTwoToneIcon
        fontSize="small"
        sx={{ color: getBranchStatusColor(branch) }}
      />
      <Typography
        variant="body2"
        textAlign="left"
        sx={{
          mt: 0.25,
          color: isActive
            ? theme.palette.primary.main
            : theme.palette.text.primary,
        }}
      >
        {branch?.name || t('master')}
      </Typography>
    </ActionButton>
  );
}

export default BranchItem;
