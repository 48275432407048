import React from 'react';
import EditIcon from 'assets/icons/EditIcon';
import SettingsIcon from 'assets/icons/SettingsIcon';
import BranchIcon from 'assets/icons/BranchIcon';
import FilesIcon from 'assets/icons/FilesIcon';

const routes = [
  {
    to: '/',
    icon: <BranchIcon />,
  },
  {
    to: '/articles',
    icon: <EditIcon />,
  },
  {
    to: '/files',
    icon: <FilesIcon />,
  },
  {
    to: '/settings',
    icon: <SettingsIcon />,
  },
];

export default routes;
