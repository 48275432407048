import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'store/authorization/defaultState';
import {
  accountActivation,
  fetchCurrentUser,
  fetchUser,
  passwordReset,
  passwordSetup,
  signIn,
  signUp,
} from 'store/authorization/thunks';

export const authorization = createSlice({
  name: 'authorization',
  initialState: defaultState,
  reducers: {
    setIsAuthorized: (draft, action: PayloadAction<boolean>) => {
      draft.isAuthorized = action.payload;
    },

    setToken: (draft, action: PayloadAction<string | null>) => {
      draft.token = action.payload;
    },

    resetAuthorizationState: (draft) => {
      draft.isAuthorized = defaultState.isAuthorized;
      draft.token = defaultState.token;
      draft.currentUser = defaultState.currentUser;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(signUp.fulfilled, (draft) => {
      draft.isRequestPending = false;
    });
    builder.addCase(signUp.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(signIn.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(signIn.fulfilled, (draft, action) => {
      draft.isRequestPending = false;
      draft.isAuthorized = true;
      draft.token = action.payload.token;
      localStorage.setItem('token', action.payload.token);
    });
    builder.addCase(signIn.rejected, (draft) => {
      draft.isRequestPending = false;
      draft.isAuthorized = false;
      draft.token = null;
      localStorage.removeItem('token');
    });

    builder.addCase(passwordReset.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(passwordReset.fulfilled, (draft) => {
      draft.isRequestPending = false;
    });
    builder.addCase(passwordReset.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(accountActivation.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(accountActivation.fulfilled, (draft) => {
      draft.isRequestPending = false;
    });
    builder.addCase(accountActivation.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(passwordSetup.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(passwordSetup.fulfilled, (draft) => {
      draft.isRequestPending = false;
    });
    builder.addCase(passwordSetup.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(fetchCurrentUser.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchCurrentUser.fulfilled, (draft, action) => {
      draft.currentUser = action.payload;
      draft.isRequestPending = false;
    });
    builder.addCase(fetchCurrentUser.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(fetchUser.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchUser.fulfilled, (draft) => {
      draft.isRequestPending = false;
    });
    builder.addCase(fetchUser.rejected, (draft) => {
      draft.isRequestPending = false;
    });
  },
});

export const { setIsAuthorized, setToken, resetAuthorizationState } =
  authorization.actions;

export default authorization.reducer;
