import React, { useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import ArticleForm from 'components/Forms/ArticleForm/ArticleForm';
import { FormData } from 'components/Forms/ArticleForm/ArticleForm';
import mapViolationsToFormErrors from 'helpers/mapViolationsToFormErrors';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { UseFormSetError } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isRequestPending } from 'store/articles/selectors';
import { currentProject } from 'store/projects/selectors';
import { createArticle } from 'store/articles/thunks';
import { currentBranch } from 'store/branches/selectors';
import { DefaultBranch } from 'store/branches/interfaces';

interface NewArticleDialogProps {
  isDialogOpen: boolean;
  handleDialogClose: () => void;
}

function ArticleDialog({
  isDialogOpen,
  handleDialogClose,
}: NewArticleDialogProps) {
  const requestPending = useAppSelector(isRequestPending);
  const project = useAppSelector(currentProject);
  const branch = useAppSelector(currentBranch);

  const dispatch = useAppDispatch();
  const { displaySuccessNotification, displayErrorNotification } =
    useDisplayNotification();

  const formRef = useRef<HTMLFormElement | null>(null);

  const handleFormSubmit = async (
    formData: FormData,
    setError: UseFormSetError<FormData>,
  ) => {
    if (!project) {
      return;
    }

    try {
      await dispatch(
        createArticle({
          [branch ? 'branch' : 'project']: {
            ...formData,
            project: project['@id'],
          },
        }),
      ).unwrap();

      displaySuccessNotification(t('success'));
      handleDialogClose();
    } catch (error) {
      mapViolationsToFormErrors<FormData>(error, setError);
      displayErrorNotification(error);
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{ pb: 1, display: 'flex', flexDirection: 'column' }}
        color="primary"
      >
        {t('new_article')}
        <Typography variant="caption" color="text.secondary">
          {t('add_new_article_to_branch', {
            branchName: branch?.name || DefaultBranch.master,
          })}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 1, display: 'flex' }}>
        <Box sx={{ pt: 1, width: '100%' }}>
          <ArticleForm onSubmit={handleFormSubmit} ref={formRef} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={handleDialogClose} disabled={requestPending}>
          {t('cancel')}
        </Button>
        <Button autoFocus onClick={handleSubmitClick} disabled={requestPending}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ArticleDialog;
