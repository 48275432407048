import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { t } from 'i18next';
import SideSection from 'components/Layout/AppDrawer/SideSection/SideSection';
import { useAppSelector } from 'store/hooks';
import { branches, currentBranch } from 'store/branches/selectors';
import { Branch } from 'store/branches/interfaces';
import BranchItem from 'pages/Branches/SideSectionContent/BranchItem/BranchItem';

interface SideSectionContentProps {
  selectedBranch: Branch | null;
  setSelectedBranch: Dispatch<SetStateAction<Branch | null>>;
  setIsBranchDialogOpen: Dispatch<SetStateAction<boolean>>;
}

function SideSectionContent({
  selectedBranch,
  setSelectedBranch,
  setIsBranchDialogOpen,
}: SideSectionContentProps) {
  const activeBranch = useAppSelector(currentBranch);
  const allBranches = useAppSelector(branches);

  return (
    <SideSection
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Box>
        <BranchItem
          branch={null}
          isSelected={selectedBranch === null}
          isActive={activeBranch === null}
          onSelect={setSelectedBranch}
        />
        {allBranches?.length ? (
          <React.Fragment>
            {allBranches.map((branch) => (
              <BranchItem
                key={branch.id}
                branch={branch}
                isSelected={selectedBranch?.id === branch.id}
                isActive={activeBranch?.id === branch.id}
                onSelect={setSelectedBranch}
              />
            ))}
          </React.Fragment>
        ) : null}
      </Box>
      <Button
        variant="contained"
        size="large"
        color="inherit"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setIsBranchDialogOpen(true)}
        sx={{
          m: 1,
        }}
      >
        {t('new_branch')}
      </Button>
    </SideSection>
  );
}

export default SideSectionContent;
