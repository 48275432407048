import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getDefaultHeaders,
  getDefaultUpdateHeaders,
} from 'store/utils/getDefaultHeaders';
import {
  Article,
  ArticleOrigin,
  ArticleParent,
  CreateArticlePayload,
  EditArticlePayload,
} from 'store/articles/interfaces';
import { ApiFilters, ApiResponse } from 'helpers/interfaces';
import buildQueryString from 'store/utils/buildQueryString';
import { DefaultBranch } from 'store/branches/interfaces';

export const fetchArticles = createAsyncThunk(
  'articles/fetchArticles',
  async (filters: ApiFilters<Article>, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/articles${buildQueryString(filters)}`,
      {
        headers: getDefaultHeaders(),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as ApiResponse<Article>);
  },
);

export const createArticle = createAsyncThunk(
  'articles/createArticle',
  async (payload: CreateArticlePayload, thunkAPI) => {
    const { branch: branchArticle, project: projectArticle } = payload;
    const article = branchArticle || projectArticle;

    if (!article) {
      return thunkAPI.rejectWithValue({});
    }

    const response = await fetch(`${process.env.REACT_APP_API_HOST}/articles`, {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: JSON.stringify({
        ...article,
        branch: article.branch === DefaultBranch.master ? null : article.branch,
        parent: article.parent === ArticleParent.none ? null : article.parent,
        origin: article.origin === ArticleOrigin.none ? null : article.origin,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as Article);
  },
);

export const editArticle = createAsyncThunk(
  'articles/editArticle',
  async (payload: EditArticlePayload, thunkAPI) => {
    const { branch: branchArticle, project: projectArticle } = payload;
    const article = branchArticle || projectArticle;

    if (!article) {
      return thunkAPI.rejectWithValue({});
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/articles/${article.id}`,
      {
        method: 'PATCH',
        headers: getDefaultUpdateHeaders(),
        body: JSON.stringify({
          ...article,
          parent: article.parent === ArticleParent.none ? null : article.parent,
        }),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as Article);
  },
);

export const fetchArticleById = createAsyncThunk(
  'articles/fetchArticleById',
  async (payload: { articleId: string }, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/articles/${payload.articleId}`,
      {
        headers: getDefaultUpdateHeaders(),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as Article);
  },
);
