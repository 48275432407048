import React, { useEffect, useRef, useState, useCallback } from 'react';
import Grid from '@mui/material/Grid2';
import Layout from 'components/Layout/Layout';
import ArticleDialog from 'components/Dialogs/ArticleDialog/ArticleDialog';
import MarkdownDiff from 'components/MarkdownDiff/MarkdownDiff';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { editArticle, fetchArticleById } from 'store/articles/thunks';
import { currentArticle, isRequestPending } from 'store/articles/selectors';
import { setCurrentArticleById } from 'store/articles/articles';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import ControlsSection from 'pages/Articles/ControlsSection/ControlsSection';
import SideSectionContent from 'pages/Articles/SideSectionContent/SideSectionContent';
import { TextField } from '@mui/material';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { extractIdFromIri } from 'helpers/extractIdFromIri';
import ArticleStatusIndicator from 'pages/Articles/ArticleStatusIndicator/ArticleStatusIndicator';
import { currentBranch } from 'store/branches/selectors';

function Articles() {
  const article = useAppSelector(currentArticle);
  const branch = useAppSelector(currentBranch);
  const requestPending = useAppSelector(isRequestPending);
  const dispatch = useAppDispatch();
  const { displayErrorNotification } = useDisplayNotification();

  const articleEditorRef = useRef<HTMLInputElement>(null);

  const [isArticleDialogOpen, setIsArticleDialogOpen] = useState(false);
  const [currentArticleContent, setCurrentArticleContent] = useState('');
  const [articleOriginContent, setArticleOriginContent] = useState('');

  const isArticleChanged = currentArticleContent !== article?.content;

  useEffect(() => {
    if (!article) {
      return;
    }

    setCurrentArticleContent(article.content);

    (async () => {
      if (!article.origin) {
        setArticleOriginContent('');
        return;
      }

      try {
        const origin = await dispatch(
          fetchArticleById({
            articleId: extractIdFromIri(article.origin),
          }),
        ).unwrap();

        setArticleOriginContent(origin.content);
      } catch (error) {
        displayErrorNotification(error);
      }
    })();

    return () => {
      setArticleOriginContent('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article, dispatch]);

  const handleArticleDialogClose = () => {
    setIsArticleDialogOpen(false);
  };

  const handleUpdateArticleContent = useCallback(async () => {
    if (!article) {
      return;
    }

    try {
      const updatedArticle = await dispatch(
        editArticle({
          [branch ? 'branch' : 'project']: {
            content: currentArticleContent,
            id: article.id,
          },
        }),
      ).unwrap();

      dispatch(setCurrentArticleById({ articleId: updatedArticle.id }));
    } catch (error) {
      displayErrorNotification(error);
    }
  }, [
    article,
    branch,
    currentArticleContent,
    dispatch,
    displayErrorNotification,
  ]);

  useEffect(() => {
    if (!article || !isArticleChanged) {
      return;
    }

    const timeout = setTimeout(() => {
      handleUpdateArticleContent();
    }, 2000);

    return () => clearTimeout(timeout);
  }, [article, handleUpdateArticleContent, isArticleChanged]);

  return (
    <>
      <Layout
        sideSection={
          <SideSectionContent setIsArticleDialogOpen={setIsArticleDialogOpen} />
        }
        topBarChildren={
          <>
            {article && (
              <ArticleStatusIndicator
                isArticleChanged={isArticleChanged}
                requestPending={requestPending}
              />
            )}
          </>
        }
        topBarSx={{
          flexGrow: 1,
          justifyContent: { sm: 'flex-end', md: 'space-between' },
          display: { xs: 'none', sm: 'flex' },
        }}
      >
        <Grid container direction="column" spacing={1} sx={{ height: '100%' }}>
          <Grid size={12}>
            <ControlsSection
              articleEditorRef={articleEditorRef}
              currentArticleContent={currentArticleContent}
              setCurrentArticleContent={setCurrentArticleContent}
            />
          </Grid>
          <Grid size={12} sx={{ display: 'flex', flexGrow: 1 }}>
            <Grid container sx={{ flexGrow: 1 }} spacing={1}>
              <Grid size={{ xs: 12, md: 6 }}>
                <RoundedBox sx={{ p: { xs: 4, xl: 6 }, height: '100%' }}>
                  {article && (
                    <TextField
                      inputRef={articleEditorRef}
                      multiline
                      fullWidth
                      type="text"
                      variant="outlined"
                      value={currentArticleContent}
                      onChange={(e) => setCurrentArticleContent(e.target.value)}
                      sx={{
                        height: '100%',
                        '& .MuiOutlinedInput-root': {
                          alignItems: 'start',
                          height: '100%',
                          padding: 0,
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                      }}
                    />
                  )}
                </RoundedBox>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <RoundedBox sx={{ p: { xs: 4, xl: 6 }, height: '100%' }}>
                  {article && (
                    <MarkdownDiff
                      newContent={currentArticleContent}
                      existingContent={articleOriginContent}
                    />
                  )}
                </RoundedBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
      <ArticleDialog
        isDialogOpen={isArticleDialogOpen}
        handleDialogClose={handleArticleDialogClose}
      />
    </>
  );
}

export default Articles;
