import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'store/articles/defaultState';
import {
  createArticle,
  editArticle,
  fetchArticleById,
  fetchArticles,
} from 'store/articles/thunks';
import { Article } from 'store/articles/interfaces';

export const articles = createSlice({
  name: 'articles',
  initialState: defaultState,
  reducers: {
    setCurrentArticleById: (
      draft,
      action: PayloadAction<{ articleId: string | null }>,
    ) => {
      const chosenProjectArticle = draft.articles.project?.find(
        (article) => article.id === action.payload.articleId,
      );

      const chosenBranchArticle = draft.articles.branch?.find(
        (article) => article.id === action.payload.articleId,
      );

      draft.currentArticle =
        chosenProjectArticle || chosenBranchArticle || null;

      if (!chosenProjectArticle?.id && !chosenBranchArticle?.id) {
        return;
      }

      localStorage.setItem(
        'article',
        chosenProjectArticle?.id || chosenBranchArticle?.id || '',
      );
    },

    resetBranchArticles: (draft) => {
      draft.articles.branch = null;
    },

    resetProjectArticles: (draft) => {
      draft.articles.project = null;
    },

    resetArticlesState: (draft) => {
      draft.articles = defaultState.articles;
      draft.currentArticle = defaultState.currentArticle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArticles.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchArticles.fulfilled, (draft, action) => {
      const { 'hydra:member': articles } = action.payload;

      if (action.meta.arg.branch) {
        draft.articles.branch = articles;
      }

      if (action.meta.arg.project) {
        draft.articles.project = articles;
      }

      const articleId = localStorage.getItem('article');

      const chosenProjectArticle = draft.articles.project?.find(
        (article) => article.id === articleId,
      );

      const chosenBranchArticle = draft.articles.branch?.find(
        (article) => article.id === articleId,
      );

      draft.currentArticle =
        chosenProjectArticle || chosenBranchArticle || null;

      draft.isRequestPending = false;
    });
    builder.addCase(fetchArticles.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(createArticle.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(createArticle.fulfilled, (draft, action) => {
      if (action.meta.arg.project) {
        draft.articles.project?.push(action.payload);
      }

      if (action.meta.arg.branch) {
        draft.articles.branch?.push(action.payload);
      }

      draft.isRequestPending = false;
    });
    builder.addCase(createArticle.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(editArticle.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(editArticle.fulfilled, (draft, action) => {
      const updateArticles = (articles: Article[] | null) => {
        if (!articles || !action.payload) {
          return;
        }

        const index = articles.findIndex(
          (article) => article.id === action.payload?.id,
        );

        if (index >= 0) {
          articles[index] = action.payload;
        }
      };

      if (action.meta.arg.project) {
        updateArticles(draft.articles.project);
      }
      if (action.meta.arg.branch) {
        updateArticles(draft.articles.branch);
      }

      draft.isRequestPending = false;
    });
    builder.addCase(editArticle.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(fetchArticleById.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchArticleById.rejected, (draft) => {
      draft.isRequestPending = false;
    });
    builder.addCase(fetchArticleById.fulfilled, (draft) => {
      draft.isRequestPending = false;
    });
  },
});

export const {
  setCurrentArticleById,
  resetBranchArticles,
  resetProjectArticles,
  resetArticlesState,
} = articles.actions;

export default articles.reducer;
