import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { t } from 'i18next';

interface ActionConfirmationDialogProps {
  isDialogOpen: boolean;
  title: string;
  content: string;
  handleConfirmAction: () => void;
  handleDialogClose: () => void;
}

function ActionConfirmationDialog({
  isDialogOpen,
  title,
  content,
  handleConfirmAction,
  handleDialogClose,
}: ActionConfirmationDialogProps) {
  const handleConfirm = () => {
    handleConfirmAction();
    handleDialogClose();
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }} color="primary">
        {title}
      </DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={handleDialogClose} color="error">
          {t('cancel')}
        </Button>
        <Button onClick={handleConfirm} autoFocus>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ActionConfirmationDialog;
